<template>
	<div class="yiantong">
		<div class="img"></div>
		<div class="felx">
			<div style="position: absolute; bottom: 130px">
				<div class="one">
					<div class="one-text1">
						<p>1. 一键文书生成（执法文书、笔录快速制作）</p>
						<p>2. 执法知识库（证据规格、指引、处罚裁量）</p>
						<p>3. 参考案例库（已收录1877篇）</p>
						<p>4. 简案快办方案</p>
						<p>5. 消费辅助调解（繁简分流调解)</p>
						<p>6. 日常监督检查移动版</p>
						<p>7. 处罚建议智能搜索（大模型）</p>
						<p>8. 智能客服（注册审批、消费投诉举报咨询）</p>
					</div>
					<div class="one-text2">
						<p>了解产品详情请咨询客服</p>
					</div>
				</div>
				<div class="two">
					<div class="two-text">
						<div class="text1">市监执法问题智能咨询</div>
						<div class="text1">和执法交流群</div>
						<div class="text2">
							<p>
								请微信扫码
								<img src="../../assets/images/home/right.png" style="
                    width: 13px;
                    height: 13px;
                    margin-left: 8px;
                    margin-top: -3px;
                  " />
							<p>或识别二维码入群</p>
							</p>
						</div>
					</div>
					<div class="two-img">
						<img src="../../assets/images/home/weChat.png" />
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="bottom_btn">
				<div style="
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 13px 0;
          ">
					<van-button type="warning" class="b_btn2" @click="toPath('/home')">
						一键文书在线体验
					</van-button>
					<van-button class="b_btn1" @click="show = true">
						联系客服
					</van-button>
				</div>
			</div>
		</div>

		<van-overlay class="mask" :show="show">
			<img src="../../assets/images/case/chat.png" alt=""
				style="width: 300px; height: 460px; margin: 30px 20px" />
			<div class="close" @click="show = false">
				<img src="../../assets/images/case/close.png" alt="" />
			</div>
		</van-overlay>
	</div>
</template>

<script>
export default {
	data () {
		return {
			show: false,
		};
	},
	methods: {
		toPath (item) {
			this.$router.push(item);
		},
	},
};
</script>

<style lang="scss" scoped>
.felx {
	display: flex;
	align-items: center;
	justify-content: center;
}

.one {
	//   position: absolute;
	width: 340px;
	height: 300px;
	border-radius: 10px 10px 10px 10px;
	border: 2px solid #ffffff;
	background: #ffffff;

	//   bottom: 270px;
	.one-text1 {
		padding: 10px 20px;
		line-height: 30px;

		p {
			color: #ff7900;
			font-weight: bold;
			font-family: SourceHanSansCN, SourceHanSansCN;
		}
	}

	.one-text2 {
		float: right;
		padding-right: 20px;

		p {
			color: #999999;
			font-size: 12px;
		}
	}
}

.two {
	width: 340px;
	height: 150px;
	border-radius: 10px 10px 10px 10px;
	border: 2px solid #ffffff;
	background: #ffffff;
	margin-top: 10px;
	padding: 20px;

	.two-text {
		float: left;

		.text1 {
			font-family: SourceHanSansCN, SourceHanSansCN;
			font-weight: bold;
			font-size: 18px;
			color: #333333;
		}

		.text2 {
			font-size: 12px;
			color: #666666;
			margin-top: 10px;
			line-height: 20px;
		}
	}

	.two-img {
		float: right;

		img {
			width: 100px;
			height: 100px;
			border: 1px solid #000000;
			border-radius: 5px;
			padding: 5px;
		}
	}
}

.yiantong {
	height: 900px;
	width: 100%;
	position: relative;

	.img {
		width: 100%;
		height: 100%;
		background: url('../../assets/images/home/yatbg.png') no-repeat center;
		background-size: cover;
		position: absolute;
		margin-top: -50px;
	}

	.bottom_btn {
		width: 100%;
		padding-bottom: env(safe-area-inset-bottom);
		background: RGBA(21, 70, 224, 1);
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 100;

		.b_btn1 {
			margin: 10px;
			background: #fff;
			color: #005ff0;
			border-radius: 24.5px;
			font-size: 16px;
			font-weight: bold;
			width: 160px;
			height: 40px;
		}

		.b_btn2 {
			margin-left: 10px;
			background: #ff7900;
			color: #fff;
			border-radius: 24.5px;
			font-size: 16px;
			font-weight: bold;
			width: 170px;
			height: 40px;
		}
	}

	.mask {
		// background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.chat {
			//   position: absolute;
			//   top: 50%;
			//   left: 50%;
			//   transform: translate(-50%, -50%);
		}

		.close {
			margin-top: 30px;

			img {
				width: 30px;
				height: 30px;
			}
		}
	}
}
</style>
