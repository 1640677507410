import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils/vant'
import './directive/index'
import 'vant/lib/index.css';
import './assets/css/reset.scss'


import importAllComponent from './components/base/index.js'
Vue.use(importAllComponent)
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
