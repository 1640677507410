//注册全局组件
import BottomBtn from './bottom-btn.vue'
import Cell from './cell.vue'
import HeadTitle from '../head-title.vue'
import MyPopup from '../my-popup.vue'
import MyDialog from '../my-dialog.vue'
import noData from './nodata.vue'
const allCompoents = {
  BottomBtn,
  Cell,
  HeadTitle,
  MyPopup,
  MyDialog,
  noData
}
export default {
  install(app) {
    Object.keys(allCompoents).forEach((key) => {
      app.component(key, allCompoents[key])
    })
    // for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    //   app.component(key, component)
    // }
  },
}
