<template>
  <div id="app">
    <keep-alive v-if="keepalive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    keepalive() {
      return this.$route.meta.keepAlive == false ? false : true;
    },
  },
};
</script>

<style>
#app {
  width: 100vw;
  height: 100%;
}

body {
  /* padding-bottom: var(--cordova-navigation-bar-height); */
}
</style>
