<template>
  <div
    :class="['nav_bar', fixed ? 'nav_fixed' : '']"
    :style="`background:${background}`"
  >
    <div class="nav_back_div">
      <!-- <img
        src="../../assets/images/base/back.png"
        alt=""
        class="nav_back"
        @click="goback"
        v-if="showBack"
      /> -->
    </div>
    <div class="nav_title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    background: {
      default: '#f1f4f6',
    },
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.nav_bar {
  padding-top: var(--cordova-status-bar-height);
  box-sizing: content-box;
  width: 100vw;
  height: 44px;
  display: flex;
  align-items: center;

  .nav_back_div {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 6px;
    position: absolute;
    left: 15px;
  }
  .nav_back {
    width: 24px;
    height: 24px;
  }
  .nav_title {
    width: 100vw;
    font-size: 18px;
    color: #333333;
    text-align: center;
  }
}
</style>
