<template>
  <div class="caselist">
    <nav-bar title="案件办理"></nav-bar>
    <div class="header">
      <van-search
        v-model="value"
        placeholder="请输入案件名称"
        show-action
        shape="round"
        background="none"
      >
        <template #action>
          <img src="../../assets/images/case/screen.png" class="search_img" />
        </template>
      </van-search>
    </div>
    <div class="content">
      <div class="item">
        <div class="title">xx超市销售过期奶粉</div>
        <div class="liner"></div>
        <div class="font">
          <div class="lable">创建时间：</div>
          <div class="value">2023-09-14 18:19:40</div>
        </div>
        <div class="font">
          <div class="lable">修改时间：</div>
          <div class="value">2023-09-14 18:19:40</div>
        </div>
        <div class="font">
          <div class="lable">当事人：</div>
          <div class="value">上海嘉泽安全设备有限公司</div>
        </div>
        <div class="font">
          <div class="lable">违法行为：</div>
          <div class="value">
            生产经营标注虚假生产日期、保质期或者超过保质期的食品、食品添加剂的行为
          </div>
        </div>
      </div>
      <!-- <div class="item">
        <div class="title">测试案件</div>
        <div class="liner"></div>
        <div class="font">
          <div class="lable">创建时间：</div>
          <div class="value">2023-09-14 18:19:40</div>
        </div>
        <div class="font">
          <div class="lable">修改时间：</div>
          <div class="value">2023-09-14 18:19:40</div>
        </div>
        <div class="font">
          <div class="lable">当事人：</div>
          <div class="value">xx超市</div>
        </div>
        <div class="font">
          <div class="lable">违法行为：</div>
          <div class="value">
            被许可人以欺骗、贿赂等不正当手段取得食品经营许可的行为
          </div>
        </div>
      </div> -->
    </div>
    <!-- <bottom-btn
      :fixed="true"
      title="新增案件"
      @click="toPath('/addCase')"
    ></bottom-btn> -->
    <van-overlay show="true" class="btn">
      <div class="head">
        <img src="../../assets/images/home/head.png" alt="" />
      </div>
      <div class="bottom_btn">
        <van-button type="info" class="b_btn1" @click="toPath('/addCase')">
          新增案件
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import NavBar from '@/components/base/nav-bar.vue';
export default {
  components: {
    NavBar,
    // Unit,
    // Personal,
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.caselist {
  min-height: 100%;
  background: #f1f4f6;
  padding-top: 44px;
  box-sizing: border-box;
  .titles {
    padding-top: 9.5px;
    text-align: center;
    font-size: 18px;
    width: 100vw;
    color: #333333;
    position: relative;
    z-index: 100;
  }
  .header {
    margin: 12px 0;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .search_img {
      width: 34px;
      height: 34px;
    }
    ::v-deep .van-search {
      padding: 0 !important;
      .van-search__content {
        background: #fff;
        width: 300px;
      }
    }
  }
  .content {
    height: calc(100vh - 178px);
    overflow-y: auto;
    .item {
      width: 343px;
      margin: 0 auto;
      margin-bottom: 10px;
      background: #fff;
      border-radius: 6px;
      padding: 15px 16px;
      box-sizing: border-box;
      &:nth-last-child(1) {
        margin-bottom: 50px;
      }
      .title {
        color: rgba(0, 0, 0, 0.82);
        font-size: 16px;
        font-weight: bold;
      }
      .liner {
        width: 100%;
        height: 0.5px;
        background: rgba(0, 0, 0, 0.06);
        margin: 14px 0 12px;
      }
      .font {
        line-height: 31px;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        .lable {
          white-space: nowrap;
          color: rgba(0, 0, 0, 0.42);
        }
        .value {
          color: rgba(0, 0, 0, 0.82);
        }
        .ml {
          margin-left: 12px;
        }
      }
    }
  }
  .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .head {
      position: absolute;
      bottom: 2px;
      z-index: 1000;
      left: 82%;
      padding-bottom: env(safe-area-inset-bottom);
    }
    img {
      width: 45px;
      height: 45px;
    }
    .bottom_btn {
      margin-left: 34px;
      position: absolute;
      bottom: 15px;
      padding-bottom: env(safe-area-inset-bottom);
      .b_btn1 {
        margin: 10px;
        background: #005ff0;
        // color: #005ff0;
        border-radius: 24.5px;
        font-size: 16px;
        font-weight: bold;
        width: 288px;
        height: 40px;
      }
    }
  }
}
</style>
