<template>
  <div
    :class="[
      'bottom_btn',
      fixed ? 'bottom_fixed' : '',
      type == 'more' ? 'pb' : '',
    ]"
  >
    <div class="b_btn1" v-if="type == 'one'">
      <van-button type="info" class="b_sure" @click="handleClick">{{
        title
      }}</van-button>
    </div>
    <div class="b_btn2" v-if="type == 'sure'">
      <van-button type="info" class="b_cancel" @click="handletitle1Click">{{
        title1
      }}</van-button>
      <van-button type="info" class="b_sure" @click="handletitle2Click">{{
        title2
      }}</van-button>
    </div>
    <div class="b_btn3" v-if="type == 'more'">
      <van-button
        type="info"
        class="b_cancel"
        @click="handletitle1Click"
        disabled
        >{{ title1 }}</van-button
      >
      <van-button type="info" class="b_sure" @click="handletitle2Click">{{
        title2
      }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 'one', //展示的类型
    },
    title: {
      default: '确定', //默认展示一个按钮的名称
    },
    title1: {
      default: '取消',
      disabled: true,
    },
    title2: {
      default: '确定',
    },
    fixed: {
      type: Boolean,
      default: false, //是否固定在底部
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    handletitle1Click() {
      this.$emit('handleClick1');
    },
    handletitle2Click() {
      this.$emit('handleClick2');
    },
  },
};
</script>

<style lang="scss" scoped>
// .pb {
//   padding-bottom: var(--cordova-navigation-bar-height);
// }
.bottom_btn {
  width: 100vw;
  background: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  .b_btn1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    .b_sure {
      background: #005ff0;
      border-radius: 24.5px;
      font-size: 16px;
      width: 288px;
      height: 40px;
    }
  }
  .b_btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    .b_sure {
      background: #005ff0;
      border-radius: 24.5px;
      font-size: 16px;
      width: 231px;
      height: 40px;
      margin-left: 12px;
    }
    .b_cancel {
      border: 2px solid #e7e8e9;
      width: 100px;
      background: #fff;
      font-size: 16px;
      border-radius: 24.5px;
      width: 100px;
      height: 40px;
      color: rgba(0, 0, 0, 0.42);
    }
  }
  .b_btn3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 13px 0;
    .b_sure {
      background: #005ff0;
      border-radius: 24.5px;
      font-size: 16px;
      width: 288px;
      height: 40px;
      margin-top: 13px;
    }
    .b_cancel {
      background: #005ff0;
      width: 288px;
      height: 40px;
      font-size: 16px;
      border-radius: 24.5px;
    }
  }
}
.bottom_fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>
