<template>
  <div class="home">
    <div class="img"></div>
    <div class="titles">易案通</div>
    <div class="search flex" @click="searchClick">
      请输入检索关键字 <img src="../../assets/images/home/search.svg" alt="" />
    </div>
    <div class="content">
      <div class="banner">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in 3" :key="item">
            <img
              :src="require(`../../assets/images/home/banner${index}.png`)"
              alt=""
              class="banner_img"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="list1 flex">
        <div class="left" @click="toPath('/caseHandle')">
          <div class="left_top flex">
            案件办理<img src="../../assets/images/home/jiantou.svg" alt="" />
          </div>
          <div class="left_bottom">
            <img src="../../assets/images/home/ajbl.png" alt="" />
          </div>
        </div>
        <div class="right flex">
          <div class="right_item flex">
            一站搜索
            <img src="../../assets/images/home/2.png" alt="" />
          </div>
          <div class="right_item flex">
            法律工具 <img src="../../assets/images/home/3.png" alt="" />
          </div>
        </div>
      </div>
      <div class="list2 flex">
        <div class="right_item flex item">
          我要反馈 <img src="../../assets/images/home/4.png" alt="" />
        </div>
        <div class="right_item flex item">
          联系我们 <img src="../../assets/images/home/5.png" alt="" />
        </div>
      </div>
    </div>
    <van-overlay show="true" class="mask">
      <div class="light">
        <div class="head">
          <img src="../../assets/images/home/head.png" alt="" />
        </div>
        <div class="list1 flex">
          <div class="left" @click="toPath('/caseHandle')">
            <div class="left_top flex">
              案件办理<img src="../../assets/images/home/jiantou.svg" alt="" />
            </div>
            <div class="left_bottom">
              <img src="../../assets/images/home/ajbl.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import NavBar from '@/components/base/nav-bar.vue';
export default {
  data() {
    return {
      showMask: true, // 控制蒙版的显示与隐藏
    };
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: var(--cordova-status-bar-height);
  height: 100%;
  background: #f1f4f6;
  position: relative;
}
.img {
  width: 100%;
  height: 570px;
  background: url('../../assets/images/home/bg.png') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
}
.titles {
  padding-top: 9.5px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  position: relative;
  z-index: 100;
}
.search {
  position: relative;
  z-index: 100;
  width: 343px;
  border-radius: 22.5px;
  margin: 0 auto;
  padding: 0 16px;
  margin-top: 21.5px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.2);
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  height: 34px;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-between;
  img {
    width: 16px;
    height: 16px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.content {
  position: relative;
  z-index: 100;
  .title {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: #333333;
  }
  .banner {
    margin-top: 12px;
    padding-left: 16px;
    .banner_img {
      width: 343px;
      height: 157.5px;
    }
  }

  .list1 {
    margin-top: 32px;
    padding: 0 16px;
    justify-content: space-between;
    .left {
      width: 163.5px;
      background: #ffffff;
      border-radius: 10px;
      height: 170px;
      z-index: 9999;
      .left_top {
        padding: 16px 16px 12px 16px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.82);
        justify-content: space-between;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .left_bottom {
        text-align: right;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
    .right {
      flex-direction: column;
      .right_item {
        width: 163.5px;
        height: 77px;
        background: #ffffff;
        border-radius: 10px;
        &:nth-child(1) {
          margin-bottom: 16px;
        }
      }
    }
  }
  .right_item {
    font-size: 13px;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.82);
    justify-content: space-between;
    img {
      width: 44px;
      height: 44px;
    }
  }
  .list2 {
    margin-top: 16px;
    justify-content: space-between;
    padding: 0 16px;
    .item {
      width: 163.5px;
      height: 77px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .light {
    margin-top: 298px;
  }
  .list1 {
    margin-top: 32px;
    padding: 0 16px;
    justify-content: space-between;
    .left {
      width: 163.5px;
      background: #ffffff;
      border-radius: 10px;
      height: 170px;
      //   z-index: 9999;
      .left_top {
        padding: 16px 16px 12px 16px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.82);
        justify-content: space-between;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .left_bottom {
        text-align: right;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
    .right {
      flex-direction: column;
      .right_item {
        width: 163.5px;
        height: 77px;
        background: #ffffff;
        border-radius: 10px;
        &:nth-child(1) {
          margin-bottom: 16px;
        }
      }
    }
  }
  .head {
    position: absolute;
    margin: 145px 155px;
    z-index: 999;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
