<template>
  <div>
    <!-- <van-popup
      :value="value"
      :position="position"
      :closeable="closeable"
      :round="round"
      @click-overlay="close"
      @click-close-icon="close"
      v-if="type == 'normal'"
    >
      <div class="popup">
        <div class="p_title">{{ title }}</div>
        <div
          class="p_item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleClick(index)"
        >
          {{ item.title }}
        </div>
      </div>
    </van-popup> -->
    <van-popup
      :value="value"
      :position="position"
      :round="round"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: height }"
    >
      <div class="popup">
        <div class="p_btn flex">
          <p @click="close">取消</p>
          <p style="color: #005ff0" @click="confirm">确定</p>
        </div>
        <div
          :class="['p_item', item.chosed ? 'p_chosed' : '']"
          v-for="(item, index) in list"
          :key="index"
          @click="handleClick(index)"
        >
          {{ item.title ? item.title : item }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: "normal",
    },
    height: {},
    show: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    round: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "bottom",
    },
    title: {},
    popuplist: {
      default: () => [],
      type: Array,
    },
    value: {},
  },
  data () {
    return {
      list: this.popuplist,
    };
  },
  methods: {
    handleClick (idx) {
      this.list.forEach((ele, index) => {
        if (idx == index) {
          this.$set(ele, "chosed", true);
        } else {
          this.$set(ele, "chosed", false);
        }
      });
      // this.$emit("click", idx);
    },
    confirm () {
      let arr = this.list.find((ele) => ele.chosed);
      this.handleClick(9999999);
      this.$emit("confirm", arr);
    },
    close () {
      this.handleClick(9999999);
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  // padding: 24px 16px 0 16px;
  color: rgba(0, 0, 0, 0.82);
  .p_title {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: bold;
    padding: 24px 16px 0 16px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .p_btn {
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.42);
    position: sticky;
    top: 0px;
    z-index: 10;
    height: 52px;
    padding: 0 16px;
    background: #fff;
  }
  .p_item {
    height: 52.5px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .p_chosed {
    color: #005ff0;
  }
}
.pt14 {
  padding-top: 14px;
}
</style>