<template>
  <van-popup :value="value" position="center" closeable @click-overlay="close" @click-close-icon="close" class="pop">
    <div class="pop_content">
      <img :src="image" alt="" />
      <div class="pop_title">{{ title }}</div>
      <div class="pop_font">{{ content }}</div>
      <van-button type="info" class="b_sure" @click="handletitle2Click">确定</van-button>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    value: {},
    title: {},
    title1: {},
    content: {},
    image: {
      default: require("../assets/images/home/out.png"),
    },
  },
  methods: {
    close () {
      this.$emit("input", false)
    },
    handletitle2Click () {
      this.$emit("click")
    },
  },
}
</script>

<style lang="scss" scoped>
.pop {
  background: none;
}

.pop_content {
  width: 280px;
  border-radius: 10px;
  padding: 20px 0 21px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;

  img {
    width: 140px;
    height: 140px;
  }

  .pop_title {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }

  .pop_font {
    margin-top: 8px;
    font-size: 15px;
    color: #333333;
  }

  .b_sure {
    background: #005ff0;
    border-radius: 24.5px;
    font-size: 16px;
    width: 230px;
    margin-top: 21px;
  }
}
</style>