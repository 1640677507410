<template>
  <div class="addcase">
    <nav-bar title="新建案件"></nav-bar>
    <div class="Tinfo">
      <head-title title="当事人"></head-title>
      <div class="tips tips1">输入案件基础信息，所有文书自动复用</div>
      <div class="content border">
        <Cell type="checkbox" title="类型" :checklist="checklist" />
        <Cell title="单位名称" v-model="params.dwmc" must type="chose" />
        <div class="tips tips2">支持ORC图片文字识别</div>
        <Cell title="统一社会信用代码" v-model="params.dwtyshxydm" type="orc" />
        <Cell
          title="主体资格证照名称"
          type="input"
          v-model="params.dwztzgzzmc"
        />
        <Cell title="住所(住址)" v-model="params.dwdz" />
        <Cell title="联系电话" v-model="params.dwlxfs" />
        <Cell title="其他联系方式" v-model="params.dwqtlxfs" />
        <Cell title="法定代表人(负责人)" v-model="params.dwfddbr" />
        <Cell title="身份证件类型" v-model="params.dwfdsfzjlx" type="chose" />
        <div class="tips tips2">支持ORC图片文字识别</div>
        <Cell title="身份证号码" v-model="params.dwfdsfzjhm" />
        <Cell title="性别" v-model="params.dwxb" type="chose" />
        <Cell title="出生日期" v-model="params.dwcsrq" type="chose" />
        <!-- <my-popup
          type="btn"
          v-model="Cardshow"
          :popuplist="idCardTypeOptions"
          height="50%"
          @confirm="Cardconfirm"
        ></my-popup> -->
      </div>
      <!-- <Unit v-if="checklist[0].checked" /> -->
      <head-title title="基本信息"> </head-title>
      <div class="tips tips1">选择案由，所有文书法律法规自动引用</div>
      <div class="content">
        <div>
          <div class="wftitle">
            <span style="color: #fa4040">*</span> 违法行为
          </div>
          <div
            class="wfitem"
            v-for="(item, index) in offenceNumber"
            :key="item.mc"
          >
            <img
              src="../../../assets/images/case/del.svg"
              alt=""
              @click="delwfxw(index)"
            />
            <span>{{ item.mc }}</span>
          </div>
          <div class="addinfo flex">
            <img src="../../../assets/images/base/add.svg" alt="" />
            添加违法行为
          </div>
        </div>
        <Cell title="案件名称" v-model="params.ajmc" must />
        <Cell
          title="发现线索/收到材料时间"
          v-model="params.fxxssdclsj"
          type="chose"
          @click="TimeChose('fxxssdclsj')"
        />
        <Cell title="案源内容" v-model="params.aynr" />
        <Cell title="案件事实" v-model="params.ajss" />
        <Cell type="checkbox" title="办理类型" :checklist="checklist1" />
        <head-title title="主办人" class="pl0"></head-title>
        <Cell
          title="主办人"
          v-model="params.zbr"
          type="inputchose"
          @click="handlePeople(1)"
        />
        <Cell
          title="主办人执法证号"
          v-model="params.zbrzfzh"
          type="inputchose"
          @click="handlePeople(2)"
        />
        <head-title title="从办人" class="pl0"></head-title>
        <Cell
          title="从办人(一)"
          v-model="params.cbr"
          type="inputchose"
          @click="handlePeople(3)"
        />
        <Cell
          title="从办人执法证号(一)"
          v-model="params.cbrzfzh"
          type="inputchose"
          @click="handlePeople(4)"
        />
        <Cell
          title="从办人(二)"
          v-model="params.cbr2"
          type="inputchose"
          @click="handlePeople(5)"
        />
        <Cell
          title="从办人执法证号(二)"
          v-model="params.cbrzfzh2"
          type="inputchose"
          @click="handlePeople(6)"
        />
      </div>
      <head-title title="其他相关人"></head-title>
      <div
        class="addinfo flex width mb0"
        @click="show = true"
        v-if="otherPeople.length < 6"
      >
        <img src="../../../assets/images/base/add.svg" alt="" />
        添加其他人员
      </div>
      <div class="btn_tips">
        <div class="tip3">点击提交并制作文书，进行下一步</div>
      </div>
    </div>
    <bottom-btn
      v-debounce="2000"
      title1="提交并制作笔录"
      title2="提交并制作文书"
      type="more"
      @handleClick1="insertajxx(1)"
      @handleClick2="toPath('/documentList')"
      style="margin-top: 16px"
    ></bottom-btn>
    <!-- <van-overlay show="true">
      <div style="position: fixed; bottom: 50%">
        <div style="margin-left: 170px">
          <img src="../../../assets/images/case/slide.png" alt="" />
        </div>
        <span style="margin-left: 120px; font-size: 16px; color: #fff"
          >滑动至【提交并制作文书】</span
        >
      </div>
    </van-overlay> -->
    <div>
      <div class="slide" v-show="visible" @touchstart="handleTouchStart">
        <div class="main">
          <div style="position: fixed; bottom: 50%">
            <div style="margin-left: 50%; margin-top: 50%">
              <img src="../../../assets/images/case/slide.png" alt="" />
            </div>
            <span style="margin-left: 110px; font-size: 16px; color: #fff"
              >滑动至【提交并制作文书】</span
            >
          </div>
          <!-- <bottom-btn
            v-debounce="2000"
            title="提交并制作文书"
            @handleClick2="toPath('/documentList')"
            style="position: absolute; bottom: 0"
          ></bottom-btn> -->
        </div>
        <!-- <div class="bottom">
          <van-button type="info" class="btn1" @click="toPath('/documentList')">
            提交并制作文书
          </van-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import NavBar from '@/components/base/nav-bar.vue';
export default {
  components: {
    NavBar,
    // Unit,
    // Personal,
  },
  data() {
    return {
      visible: true,
      startX: 0,
      endX: 0,
      peopleType: [
        { title: '委托代理人', id: 1 },
        { title: '其他相关人', id: 2 },
      ],
      checklist: [
        { lable: '单位', checked: true, id: 1 },
        { lable: '个体户', checked: false, id: 2, disabled: true },
        { lable: '个人', checked: false, id: 3, disabled: true },
      ],
      checklist1: [
        { lable: '简易程序', checked: false, id: 1, disabled: true },
        { lable: '普通程序', checked: true, id: 2 },
      ],
      bltype: [{ title: '询问笔录' }, { title: '现场笔录' }],
      show: false,
      showpeople: false,
      showbl: false,
      // dateshow: false,
      offence: '',
      offenceNumber: [],
      grparams: {},
      gthparams: {},
      dwparams: {},
      fxxssdclsj: '',
      params: {
        dwmc: '上海嘉泽安全设备有限公司', // text, --单位名称
        dwtyshxydm: '913101150809288985', // text, --统一社会信用代码
        dwztzgzzmc: '营业执照', // text, --主体资格证照名称
        dwdz: '浦东新区南汇新城镇芦潮港路17581幢', // text, --住所（住址）
        dwlxfs: '', // text, --联系电话
        dwqtlxfs: '', // text, --其他联系方式
        dwfddbr: '郑伟', // text, --法定代表人（负责人）
        dwfdsfzjlx: '', // text, --身份证件类型
        dwfdsfzjhm: '440923197209250033', // text, --身份证件号码
        dwxb: '男', // text, --性别
        dwcsrq: '1972-09-25', // text, --出生日期
        ajmc: '', //案件名称
        fxxssdclsj: '', // --发现线索/收到材料时间
        aynr: '', //案源内容
        ajss: '', //案件事实
        bllx: '2', //--办理类型
        zbr: '张三', // text, --主办人
        zbrzfzh: '2010110110', // text, --主办人执法证号
        cbr: '李四', //text, --从办人
        cbrzfzh: '2010112310', // text, --从办人执法证号
        cbr2: '', // text --承办人2,
        cbrzfzh2: '', // text --从办人执法证号
        ay: '', // text, --案由
        ayid: '', // text, --案由id
        aylb: '', // text, --案由类别
        wfxwlx: '', // text, --违法行为类型
        wfxw: '', // text, --违法行为编码
        wfxwmc: '', // text, --违法行为名称
      },
      otherPeople: [],
      otherList: [
        { lable: '姓名', value: 'xm' },
        { lable: '身份证件类型', value: 'sfzjlx' },
        { lable: '身份证号码', value: 'sfzjhm' },
        { lable: '性别', value: 'xb' },
        { lable: '出生日期', value: 'csrq' },
        { lable: '联系电话', value: 'lxfs' },
        { lable: '其他联系方式', value: 'qtlxfs' },
        { lable: '工作单位', value: 'gzdw' },
        { lable: '职务', value: 'zw' },
        { lable: '住所(住址)', value: 'dz' },
      ],
      zfryxxList: [],
      Peopleindex: '',
    };
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
      this.visible = true;
    },
    handleTouchStart() {
      console.log('页面滚动了');
      this.visible = false;
    },
  },
  activated() {
    this.visible = true;
    // window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    // window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.addcase {
  min-height: 100vh;
  background: #f1f4f6;
  padding-top: 44px;
  overflow-y: hidden;
  .flex {
    display: flex;
    align-items: center;
  }
  .addinfo {
    height: 52px;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #005ff0;
    img {
      width: 14px;
      height: 14px;
      margin-right: 12px;
    }
  }
  .width {
    width: 343px;
    background: #fff;
    border-radius: 12px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .content {
    width: 343px;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    .pl0 {
      padding-left: 0 !important;
      font-size: 16px !important;
    }
  }
  .popup {
    padding: 24px 16px 0 16px;
    color: rgba(0, 0, 0, 0.82);
    .p_title {
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: bold;
    }
    .p_item {
      height: 52.5px;
      font-size: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}
.Tinfo {
  height: 100%;
  overflow-y: auto;
  // padding-bottom: 30px;
}
.titles {
  padding-top: 9.5px;
  text-align: center;
  font-size: 18px;
  width: 100vw;
  color: #333333;
  position: relative;
  z-index: 100;
}
.tips {
  float: right;
  margin-right: 16px;
  padding: 1px 5px;
  border-radius: 6px;
  font-weight: bold;
  background: #ff7900;
  color: #f1f4f6;
}
.tips1 {
  margin-top: -36px;
}
.tips2 {
  margin-top: 20px;
}
.btn_tips {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tip3 {
    float: left;
    margin-bottom: -3px;
    padding: 1px 5px;
    border-radius: 6px;
    font-weight: bold;
    background: #ff7900;
    color: #f1f4f6;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.flex {
  display: flex;
  align-items: center;
}
.addinfo {
  height: 52px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #005ff0;
  img {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
}
.width {
  width: 343px;
  background: #fff;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
}
.mb0 {
  margin-bottom: 0px;
}
.content {
  width: 343px;
  margin: 0 auto;
  background: #fff;
  border-radius: 12px;
  .other_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 16px 0 0;
    div {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.82);
      font-weight: bold;
      &::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 17px;
        background: #005ff0;
        border-radius: 2px;
        margin-right: 14px;
      }
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .wftitle {
    padding: 21px 0 16px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
  }
  .wfitem {
    display: flex;
    align-items: flex-start;
    padding: 0 25px 16px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.82);
    img {
      width: 20px;
      height: 20px;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }
  .item {
    padding: 8px 16px;
    justify-content: space-between;
    align-items: flex-start;
    span {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.42);
    }
    div {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.82);
      display: flex;
      align-items: flex-start;
      max-width: 195px;
      text-align: right;
      img {
        width: 14px;
        height: 14px;
        position: relative;
        top: 6px;
        margin-left: 4px;
      }
    }
  }
  .pl0 {
    padding-left: 0 !important;
    font-size: 16px !important;
  }
}
.border {
  border-radius: 12px 12px 0 0;
}
.popup {
  padding: 24px 16px 0 16px;
  color: rgba(0, 0, 0, 0.82);
  .p_title {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: bold;
  }
  .p_item {
    height: 52.5px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.bottom {
  position: absolute;
  bottom: 0;
  .btn1 {
    position: absolute;
    bottom: 13px;
    width: 200px;
    margin-left: 44px;
    background: #005ff0;
    border-radius: 24.5px;
    font-size: 16px;
    width: 288px;
    height: 40px;
  }
}
img {
  width: 60px;
}
</style>
