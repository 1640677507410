<template>
  <div class="main">
    <nav-bar title="预览" fixed></nav-bar>
    <div class="content">
      <img src="../../../assets/images/case/pdf.jpg" alt="" />
      <div class="tips">
        该文书已完成，了解更多功能或申请开通试用，请联系我司客服。
      </div>
      <div class="bottom_btn">
        <van-button class="b_btn1" @click="show = true"> 联系客服 </van-button>
      </div>
    </div>
    <van-overlay class="mask" :show="show">
      <img
        src="../../../assets/images/case/chat.png"
        alt=""
        style="width: 300px; height: 460px"
      />
      <div class="close" @click="show = false">
        <img src="../../../assets/images/case/close.png" alt="" />
      </div>
    </van-overlay>
    <!-- <bottom-btn title="联系客服" fixed @click="submit"></bottom-btn> -->
  </div>
</template>

<script>
import NavBar from '@/components/base/nav-bar.vue';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      show: false,
      checklist: [
        { wsmc: '案件来源登记表', chosed: true },
        { wsmc: '接受证据材料清单', checked: false },
        { wsmc: '投诉/举报分送情况告知书', checked: false },
        { wsmc: '送达地址确认书', checked: false },
        { wsmc: '投诉不予受理决定书', checked: false },
        { wsmc: '投诉调解通知书', checked: false },
        { wsmc: '投诉终止调解决定书', checked: false },
        { wsmc: '投诉调解书', checked: false },
        { wsmc: '建议书', checked: false },
        { wsmc: '案件移送函', checked: false },
        { wsmc: '立案审批表', checked: false },
      ],
    };
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  //   min-height: 100%;
  //   background: #f1f4f6;
  padding-top: 44px;
  img {
    width: 370px;
    height: 520px;
  }
  .content {
    margin: 0 auto;
    background: #fff;
    margin-top: 12px;
    padding: 0 10px;
  }
  .tips {
    // margin: 20px auto;
    padding: 1px 5px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    background: #ff7900;
    color: #f1f4f6;
  }
  .bottom_btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: env(safe-area-inset-bottom);
    // position: absolute;
    // left: 30%;
    // top: 50%;
    // background: #fff;
    .b_btn1 {
      background: #fff;
      color: #005ff0;
      border-radius: 24.5px;
      font-size: 16px;
      font-weight: bold;
      width: 150px;
      height: 40px;
    }
  }
  .mask {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .chat {
    }
    .close {
      margin-top: 30px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
