<template>
  <div class="cell" ref="cells">
    <div class="ce_title">
      <span style="color: #fa4040" v-if="must">*</span>{{ title }}
    </div>
    <!-- 正常输入框 -->
    <div class="ce_main" v-if="type == 'input'">
      <div class="ce_ipt_del">
        <input
          type="text"
          class="ce_ipt"
          :placeholder="placeholder"
          :value="value"
          @input="handleInput"
          ref="ipt"
          @focus="test"
          disabled="true"
        />
        <!-- <img
          src="../../assets/images/base/del.png"
          alt=""
          class="ce_del_img"
          v-if="value && value.length != 0"
          @click="delValue"
        /> -->
      </div>
    </div>
    <!-- 正常输入框 -->
    <div class="ce_main" v-if="type == 'inputchose'">
      <div class="ce_ipt_del">
        <input
          type="text"
          class="ce_ipt"
          placeholder="请输入或选择"
          :value="value"
          @input="handleInput"
          ref="ipt"
          @focus="test"
          disabled="true"
        />
        <!-- <img
          src="../../assets/images/base/right.svg"
          alt=""
          class="ce_chose_img"
          @click="handleChose"
        /> -->
      </div>
    </div>
    <!-- 要触发点击事件的 -->
    <div class="ce_main" v-if="type == 'chose'" @click="handleChose">
      <div :class="['ce_chose', value ? 'value' : '']">
        {{ value ? value : '请选择' }}
        <img
          src="../../assets/images/base/right.svg"
          alt=""
          class="ce_chose_img"
          v-if="showIcon"
        />
      </div>
    </div>
    <!-- 选择框 -->
    <div class="ce_main" v-if="type == 'checkbox'">
      <van-checkbox
        v-model="item.checked"
        v-for="(item, index) in checklist"
        :key="index"
        @click="checkClick(index)"
        :disabled="item.disabled"
        >{{ item.lable }}</van-checkbox
      >
    </div>
    <!-- orc识别 -->
    <div class="ce_main" v-if="type == 'orc'">
      <div class="ce_ipt_del">
        <input
          type="text"
          class="ce_ipt"
          :placeholder="placeholder"
          :value="value"
          @input="handleInput"
          disabled="true"
        />
        <img src="../../assets/images/base/ocr.svg" alt="" class="ce_del_img" />
        <van-action-sheet
          v-model="ocrshow"
          :actions="actions"
          cancel-text="取消"
          close-on-click-action
          @cancel="ocrshow = false"
          @select="selectocr"
        />
        <van-uploader :after-read="afterRead" ref="uploader" v-show="false" />
      </div>
    </div>
    <div class="ce_liner" v-if="type != 'checkbox'"></div>
  </div>
</template>

<script>
// import { ocrPhotography } from "@/utils/camera"
export default {
  props: {
    title: {
      type: String,
    },
    value: {},
    must: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'input',
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    checklist: {
      type: Array,
    },
    showIcon: {
      default: true,
      type: () => Array,
    },
  },
  data() {
    return {
      checkValue: this.value,
      ocrshow: false,
      actions: [{ name: '拍摄' }, { name: '从相册选择' }],
    };
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
    handleChose() {
      this.$emit('click');
    },
    test() {
      this.$emit('focus');
      this.$refs.cells.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    delValue() {
      this.$emit('input', '');
    },
    orcClick() {
      this.ocrshow = true;
      this.$emit('ocrClick');
    },
    checkClick(idx) {
      this.$emit('checkClick', idx);
    },
    // ocr动作面板点击
    selectocr(e) {
      console.log(e);
      // let that = this
      // if (e.name == "从相册选择") {
      //   this.$refs.uploader.$el.querySelector("input").click()
      // } else if (e.name == "拍摄") {
      //   ocrPhotography(this)
      // }
    },
    afterRead(file) {
      this.$emit('orcfile', file);
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  width: 100%;
  padding: 21px 16px 0 16px;
  box-sizing: border-box;

  // background: #fff;
  .ce_title {
    color: rgba(0, 0, 0, 0.42);
    font-size: 16px;
  }

  .ce_main {
    .ce_ipt_del {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.82);

      .ce_ipt {
        width: 90%;
        height: 24px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.82);

        &::placeholder {
          color: rgba(0, 0, 0, 0.22);
          font-size: 16px;
          line-height: 24px;
        }
      }

      .ce_del_img {
        width: 16px;
        height: 16px;
      }
    }

    .ce_chose {
      margin-top: 16px;
      display: flex;
      color: rgba(0, 0, 0, 0.22);
      line-height: 24px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ce_chose_img {
      width: 14px;
      height: 14px;
    }

    .value {
      color: rgba(0, 0, 0, 0.82);
    }

    ::v-deep .van-checkbox {
      height: 52px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    ::v-deep .van-checkbox__label {
      color: rgba(0, 0, 0, 0.82);
      font-size: 16px;
    }
  }

  .ce_liner {
    margin-top: 20px;
    width: 100%;
    height: 0.5px;
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
