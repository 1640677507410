// debounceDirective.js
export default {
  inserted(el, delay) {
    el.addEventListener('click', () => {
      el.style.pointerEvents = 'none';
      setTimeout(() => {
        el.style.pointerEvents = 'auto';
      }, delay.value || 300);
    });
  }
};
