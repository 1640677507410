<template>
  <div class="heade_title">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: {},
  },
};
</script>

<style lang="scss" scoped>
.heade_title {
  color: rgba(0, 0, 0, 0.82);
  font-size: 18px;
  font-weight: bold;
  padding: 16px 0 12px 16px;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 16px;
    background: #005ff0;
    margin-right: 14px;
    border-radius: 2px;
  }
}
</style>