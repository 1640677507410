<template>
  <div class="newdocuments">
    <nav-bar title="案件来源登记表" fixed></nav-bar>
    <div class="content">
      <Cell title="发文单位：" v-model="fwdw" />
      <Cell title="登记号：" v-model="djh" must type="chose" />
      <Cell title="来源分类：" type="checkbox" :checklist="checklist" must />
      <Cell
        title="发现线索/收到材料时间"
        type="chose"
        must
        :showIcon="false"
        v-model="cluetime"
      />
      <Cell title="监督检查人(一)：" v-model="jcr1" />
      <Cell title="所属单位(一)：" v-model="dw1" />
      <Cell title="监督检查人(二)：" v-model="jcr2" />
      <Cell title="所属单位(二)：" v-model="dw2" />
      <Cell title="监督检查人(三)：" v-model="jcr3" />
      <Cell title="所属单位(三)：" v-model="dw3" />
      <Cell title="案源内容：" v-model="aynr" must type="chose" />
      <Cell title="登记人：" v-model="djr" placeholder="主办人" />
      <Cell title="登记人签字时间：" type="chose" v-model="signtime" />
      <Cell title="备注：" v-model="bz" />
    </div>
    <div class="tips tips2">点击预览,进行下一步</div>
    <div class="bottom flex">
      <div class="show flex" @click="toPath('/pdf')">
        <img src="../../../assets/images/case/view.png" alt="" />
        预览
      </div>
      <!-- <div class="show flex">
        <img src="../../../assets/images/case/save.png" alt="" />
        保存
      </div> -->
      <van-button type="info" class="b_btn" disabled>生成文书</van-button>
    </div>
    <van-overlay
      :show="showOverlay"
      class="slide"
      @touchstart="handleTouchStart"
    >
      <div>
        <div style="position: fixed; bottom: 50%">
          <div style="margin-left: 160px; margin-top: 50%">
            <img
              src="../../../assets/images/case/slide.png"
              alt=""
              style="width: 60px"
            />
          </div>
          <span style="margin-left: 150px; font-size: 16px; color: #fff"
            >滑动至【预览】</span
          >
        </div>
        <!-- <bottom-btn
          v-debounce="2000"
          title="提交并制作文书"
          @handleClick2="toPath('/documentList')"
          style="position: absolute; bottom: 0"
        ></bottom-btn> -->
        <!-- <div class="bottom_btn">
          <div class="bottom1 flex">
            <div class="show flex" @click="toPath('/pdf')">
              <img src="../../../assets/images/case/view.png" alt="" />
              预览
            </div>
          </div>
        </div> -->
      </div>
    </van-overlay>
  </div>
</template>
<script>
import NavBar from '@/components/base/nav-bar.vue';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      showOverlay: true,
      fwdw: '综合执法处',
      djh: 'XX市市场监督管理局消保委案源〔2024〕 号',
      cluetime: '2023-09-11',
      jcr1: '张三',
      dw1: '综合执法处',
      jcr2: '李四',
      dw2: '综合执法处',
      dw3: '综合执法处',
      djr: '张三',
      bz: '',
      aynr: '接到网格员巡查，xx路xx便利店销售过期食品.....',
      signtime: '2024-03-28',
      checklist: [
        { lable: '监督检查', checked: true, id: 1 },
        { lable: '投诉', checked: false, id: 2, disabled: true },
        { lable: '举报', checked: false, id: 3, disabled: true },
        { lable: '其他部门移送', checked: false, id: 4, disabled: true },
        { lable: '上级交办', checked: false, id: 5, disabled: true },
        { lable: '其他', checked: false, id: 6, disabled: true },
      ],
    };
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
      this.showOverlay = true;
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.showOverlay = false; // 滚动时隐藏遮罩层
      } else {
        this.showOverlay = true;
      }
      console.log(window.scrollY);
    },
    handleTouchStart() {
      console.log('页面滚动了');
      this.showOverlay = false;
    },
  },
  activated() {
    this.showOverlay = true;
    // window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    // window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.newdocuments {
  min-height: 100vh;
  background: #f1f4f6;
  padding-top: 44px;
  .content {
    margin: 0 auto;
    margin-top: 12px;
    width: 343px;
    background: #fff;
    border-radius: 6px;
    height: 100%;
    overflow-y: auto;
    .pl0 {
      padding-left: 0 !important;
      font-size: 16px !important;
    }
    .addinfo {
      color: #005ff0;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .bottom {
    width: 100vw;
    margin-top: 20px;
    z-index: 20;
    background: #fff;
    padding: 13px 0;
    justify-content: space-around;
    .show {
      flex-direction: column;
      color: rgba(0, 0, 0, 0.82);
      font-size: 11px;
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
    }
    .b_btn {
      width: 230px;
      height: 40px;
      border-radius: 24.5px;
      background: #005ff0;
      font-size: 16px;
    }
  }
  .slide {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .bottom_btn {
      margin-left: 16px;
      position: absolute;
      bottom: 14px;
      justify-content: space-around;
      background-color: #fff;
      .bottom1 {
        z-index: 20;
        background: #fff;
        width: 45px;
        height: 45px;
        justify-content: space-around;
        .show {
          flex-direction: column;
          color: rgba(0, 0, 0, 0.82);
          font-size: 11px;
          img {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;
          }
        }
        .b_btn {
          width: 230px;
          height: 40px;
          border-radius: 24.5px;
          background: #005ff0;
          font-size: 16px;
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
      .b_btn1 {
        margin: 10px;
        background: #005ff0;
        // color: #005ff0;
        border-radius: 24.5px;
        font-size: 16px;
        font-weight: bold;
        width: 288px;
        height: 40px;
      }
    }
  }
}
.tips {
  float: left;
  margin-left: 30px;
  margin-bottom: -3px;
  padding: 1px 5px;
  border-radius: 6px;
  font-weight: bold;
  background: #ff7900;
  color: #f1f4f6;
  //   width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
