import Vue from 'vue';
import { Button } from 'vant';
import { Tab, Tabs } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Search } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Overlay } from 'vant';
import { Area } from 'vant';
import { Popup } from 'vant';
import { DatetimePicker } from 'vant';
import { Uploader } from 'vant';
import { Toast } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { ActionSheet } from 'vant';
import { Field } from 'vant';
import { Loading } from 'vant';
import { List } from 'vant';
import { Form } from 'vant';
import { Dialog } from "vant";
import { Stepper } from "vant";
import { Picker } from 'vant';
Vue.use(Picker);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Stepper);
Vue.use(List);
Vue.use(Loading);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Overlay);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Search);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs)
Vue.use(Checkbox);
Vue.use(CheckboxGroup);