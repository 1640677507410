<template>
  <div class="booktype">
    <nav-bar title="选择文书类型" fixed></nav-bar>
    <div class="content">
      <div class="item" v-for="(item, index) in checklist" :key="index">
        {{ item.wsmc }}
        <img
          src="../../../assets/images/case/chose.svg"
          alt=""
          v-if="item.chosed"
        />
      </div>
    </div>
    <bottom-btn
      type="sure"
      :fixed="true"
      @handleClick2="toPath('/documentMake')"
    ></bottom-btn>
    <van-overlay show="true" class="mask">
      <div class="head">
        <img src="../../../assets/images/home/head.png" alt="" />
      </div>
      <div class="bottom_btn">
        <van-button type="info" class="b_btn1" @click="toPath('/documentMake')">
          确定
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import NavBar from '@/components/base/nav-bar.vue';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      checklist: [
        { wsmc: '案件来源登记表', chosed: true },
        { wsmc: '接受证据材料清单', checked: false },
        { wsmc: '投诉/举报分送情况告知书', checked: false },
        { wsmc: '送达地址确认书', checked: false },
        { wsmc: '投诉不予受理决定书', checked: false },
        { wsmc: '投诉调解通知书', checked: false },
        { wsmc: '投诉终止调解决定书', checked: false },
        { wsmc: '投诉调解书', checked: false },
        { wsmc: '建议书', checked: false },
        // { wsmc: '案件移送函', checked: false },
        // { wsmc: '立案审批表', checked: false },
      ],
    };
  },
  methods: {
    toPath(item) {
      this.$router.push(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.booktype {
  height: 100%;
  background: #f1f4f6;
  padding-top: 44px;

  .content {
    width: 343px;
    margin: 0 auto;
    background: #fff;
    border-radius: 6px;
    margin-top: 12px;
    padding: 0 16px;
    .item {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:nth-last-child(1) {
        border-bottom: none;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.mask {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .head {
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: 1px;
    z-index: 1000;
    right: 2%;
  }
  img {
    width: 45px;
    height: 45px;
  }
  .bottom_btn {
    margin-left: 120px;
    position: fixed;
    bottom: 13px;
    padding-bottom: env(safe-area-inset-bottom);
    .b_btn1 {
      background: #005ff0;
      // color: #005ff0;
      border-radius: 24.5px;
      font-size: 16px;
      font-weight: bold;
      width: 244px;
      height: 40px;
    }
  }
}
</style>
