import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes =
  [
		{ path: "/", redirect: "/yiantong" },
		// { path: "/login", component: require("../pages/login/index.vue").default },
		{ path: "/home", component: require("../pages/home/index.vue").default },
		{ path: "/yiantong", component: require("../pages/yiantong/index.vue").default },
		{ path: "/caseHandle", component: require("../pages/caseHandle/index.vue").default },
		{ path: "/addCase", component: require("../pages/caseHandle/addCase/index.vue").default },
		{ path: "/documentList", component: require("../pages/caseHandle/addCase/documentList.vue").default },
		{ path: "/documentMake", component: require("../pages/caseHandle/addCase/documentMake.vue").default },
		{ path: "/pdf", component: require("../pages/caseHandle/addCase/pdf.vue").default },

  ]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
