<template>
  <div class="nodata">
    <img src="../../assets/images/base/nodata.png" alt="" />
    <div class="no_font">暂无数据</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.nodata {
  text-align: center;

  img {
    width: 180px;
    height: 180px;
  }

  .no_font {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
  }
}
</style>
